"use strict";

(function ($) {

// shim layer with setTimeout fallback
window.requestAnimFrame = (function(){
  return  window.requestAnimationFrame       ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame    ||
          function( callback ){
            window.setTimeout(callback, 1000 / 60);
          };
})();

$(document).ready(function() {

  class Boekarest {
    constructor() {
      this.kind = 'Boekarest';
      this.menuToggle = $('section.menu button.toggle');
      this.menuClose = $('#mobile-menu button.close');
      this.mobileMenu = $('#mobile-menu');
      this.mobileMenuLinks = $('.mobile-menu__main a');
      this.scrollLinks = $('.mobile-menu__main a, .menu__main a');
      this.menuTO = null;
      this.popup = $('section.popup');
      this.attachListeners();
    }
    
    attachListeners() {
      var that = this;
      this.menuToggle.bind('click.monkberry', () => {
      	if (!this.mobileMenu.hasClass('open')) this.showMenu();
      	else this.hideMenu();	
      });
      this.menuClose.bind('click.monkberry', () => { this.hideMenu(); });
      this.scrollLinks.bind('click.monkberry', function(e) {
        that.scrollTo($($(this).attr('href')));
        e.preventDefault();
        return false;
      });
      if (this.popup.length) {
        this.popup.find('button.close').bind('click.monkberry', () => {
          this.popup.remove();
          $('body').addClass('popup-closed');
        });
      }
      else {
        $('body').addClass('no-popup');
      }
      $(window).bind('scroll.menu resize.menu', () => {
        window.requestAnimFrame(() => { this.updatePosition(); });
      });
      this.updatePosition();
    }

    showMenu() {
      this.mobileMenu.addClass('active');
      this.menuTO = setTimeout(() => { this.mobileMenu.addClass('shown'); }, 50);
    }

    hideMenu() {
      this.mobileMenu.removeClass('shown');
      this.menuTO = setTimeout(() => { this.mobileMenu.removeClass('active'); }, 500);
    }
    
    scrollTo($el) {
      this.hideMenu();
      var y = $el.offset().top;
      var speed = Math.floor(Math.abs($(window).scrollTop() - y)/3);
      $('html, body').stop(true, true).animate({
          scrollTop: y
      }, speed, function() {
        $el.focus();
        if ($el.is('select')) {
          var event = document.createEvent('MouseEvents');
          event.initMouseEvent('mousedown', true, true, window);
          $el.get(0).dispatchEvent(event);
        }
      });
    }

    updatePosition() {
      var scrollTop = $(document).scrollTop();
      $('body').toggleClass('scrolled', scrollTop > 100);
    }
  }

  new Boekarest();

  $('body').addClass('animated');
  setTimeout(function() {
    new ScrollTrigger({
      once: true,
      toggle: {
        visible: 'visibleToggle',
        hidden: 'hiddenToggle',
        addHeight: true,
        centerVertical: true
      }
    });
  }, 50);
  
});

})(jQuery);
